import InteractiveField from './interactive-field';
import localeMixin from '../locale/locale-mixin';
import {isString} from '../utils/types';
import formatBytes from '../utils/format-bytes';


class FileField extends localeMixin(InteractiveField) {

	constructor({
		root,
		element,
		eventsEnabled = true,
		asyncEvents = true,
		invalidClass = 'invalid',
		progressClass = 'progress',
		emptyClass = 'empty',
		errorsSelector,
		name,
		type,
		errorTpl,
		baseMediaUrl = ''
	}) {
		super({
			root: root, element: element,
			eventsEnabled: eventsEnabled, asyncEvents: asyncEvents, invalidClass: invalidClass,
			errorsSelector: errorsSelector, name: name, type: type, errorTpl: errorTpl
		});
		this.baseMediaUrl = baseMediaUrl;
		this.progressClass = progressClass;
		this.emptyClass = emptyClass;
		this.value = {};
	}


	prepare() {
		super.prepare();
		this.infoElement = this.element.querySelector(this.dataSelector('info'));
		this.progressElement = this.element.querySelector(this.dataSelector('uploadProgress'));
		this.showLink = this.dataAttr(this.infoElement).get('showLink', false);
		this.removeButton = this.element.querySelector(this.dataSelector('removeFile'));
		const fileInfo = this.dataAttr(this.element.querySelector(this.dataSelector('fileInfo'))).get('fileInfo');
		this.listeners.remove = this.events.on(this.removeButton, 'click', this.onRemove.bind(this));
		this.value = fileInfo;
		this.update(this.value);
		this.acceptedTypes = this.getInput().getAttribute('accept').toLowerCase().split(/\s*,\s*/);
		this.maxSize = this.dataAttr().get('maxSize');
	}


	onChange(event) {
		if (this.enabled && this.eventsEnabled) {
			const files = this.getFiles();
			if (files instanceof FileList && files.length) {
				const file = files.item(0);
				if (file.size === 0) {
					this.setErrors([this.getText('form/error/uploadFileEmpty')]);
					return;
				}
				if (file.size > this.maxSize) {
					this.setErrors([this.getText('form/error/uploadFileTooBig')]);
					return;
				}
				if (this.acceptedTypes.indexOf(file.type.toLowerCase()) === -1) {
					this.setErrors([this.getText('form/error/uploadTypeNotAllowed')]);
					return;
				}

				this.resetErrors();
				this.getInput().disabled = true;
				this.processChange(file);
				super.onChange(event);
			}
		}
	}


	onRemove(event, target) {
		target.blur();
		if (this.enabled) {
			const input = this.getInput();
			input.value = '';
			input.type = '';
			input.type = 'file';
			this.value = {};
			this.update(this.value);
			this.triggerEvent('change');
		}
	}


	onProgress(event) {
		const percent = event.total > 0 ? Math.round(event.loaded / event.total * 100) : 0;
		this.infoElement.textContent = percent + '%';
		if (this.progressElement) {
			this.progressElement.style.width = percent + '%';
		}
	}


	getMediaUrl(path) {
		return this.baseMediaUrl + '/' + path;
	}


	processChange(file) {
		this.classList(this.element).add(this.progressClass);
	}

	update(value) {
		if (isString(value)) {
			if (!value.length) {
				value = '{}';
			}
			value = JSON.parse(value);
		}
		this.updateInfo(value);
		if (value && 'path' in value) {
			this.classList(this.element).remove(this.progressClass);
			this.classList(this.element).remove(this.emptyClass);
		} else {
			this.classList(this.element).add(this.emptyClass);
			this.classList(this.element).remove(this.progressClass);
		}
		this.getInput().disabled = false;
		this.value = value;
	}


	updateInfo(value) {
		if (value && 'path' in value) {
			let originalName = value.originalName;
			if (originalName.length > 40) {
				originalName = originalName.substr(0, 37) + '...';
			}
			const info = this.template.escape(originalName) + '<br/>' +
				value.ext.toUpperCase() + ' ' +
				(value.width > 0 && value.height > 0 ? value.width + '×' + value.height + ' ' : '') +
				formatBytes(value.size);
			// if (this.showLink) {
			// 	info += ' <a target="_blank" rel="noopener" href="' + this.getMediaUrl(value.path) + '">Link</a>';
			// }
			this.infoElement.innerHTML = info;
		} else {
			this.infoElement.textContent = '';
		}
	}


	resetUploadProcess() {
		this.update(this.value);
	}


	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('input[type="file"]');
		}
		return this.input;
	}


	writeValue(value) {
		this.update(value);
	}


	readValue() {
		return this.value;
	}


	getFiles() {
		return this.getInput().files;
	}


	hasFiles() {
		return this.getInput().files.length > 0;
	}


	setFocus() {
		this.getInput().focus();
	}


	setBlur() {
		this.getInput().blur();
	}


	enableInput() {
		this.getInput().disabled = false;
	}


	disableInput() {
		this.getInput().disabled = true;
	}

}


export default FileField;
